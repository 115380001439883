import { isDevelopment } from '@/env';
import { FrequentOrders } from '@/types';
import { ChargeeNumbers, InternetNumbers, User } from '@/types/user';

type ClientOptions = {
  baseUrl: string;
};

const createResource = <T extends () => {}>(key: string[], fn: T) => {
  return {
    key,
    fetcher: fn,
  };
};
export const createQueryPayload = (
  payload: ReturnType<typeof createResource>,
) => {
  return {
    queryKey: payload.key,
    queryFn: () => payload.fetcher(),
  };
};

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

type Query = Record<string, string | number>;

type RequestOptions = {
  path: `/${string}`;
  method: HttpMethod;
  query?: Query;
  body?: BodyInit;
};

export type PaginationQuery = {
  page: number;
  limit: number;
};

export class Client {
  private baseUrl: string;
  constructor(options: ClientOptions) {
    this.baseUrl = options.baseUrl;
  }

  private async request<T = any>(options: RequestOptions) {
    // TODO: handle errors
    const { path, method, query, body } = options;
    const queryString = query
      ? '?' +
        Object.entries(query)
          .map(([k, v]) => `${k}=${v.toString()}`)
          .join('&')
      : '';
    const fullUrl = `${this.baseUrl}${path}${queryString}`;
    const response = await fetch(fullUrl, {
      method,
      ...(body && { body }),
    });

    if (response.ok) {
      return (await response.json()) as T;
    }

    throw { message: 'Error happened.', response: await response.json() };
  }

  public readonly orders = {
    list: {
      charge: createResource(['charge-orders'], (query?: PaginationQuery) =>
        this.request({
          path: '/v1/net-charge/orders/charge',
          method: 'GET',
          query,
        }),
      ),
      internet: createResource(['internet-orders'], (query?: PaginationQuery) =>
        this.request({
          path: '/v1/net-charge/orders/internet',
          method: 'GET',
          query,
        }),
      ),
      all: createResource(['all-orders'], async (query?: PaginationQuery) => {
        const res = await this.request({
          path: '/v1/net-charge/orders/all',
          method: 'GET',
          query,
        });

        if (res.content && res.content?.length) {
          return res.content;
        }

        return [];
      }),
    },
    frequents: createResource(['all-frequent-orders'], () =>
      this.request<FrequentOrders[]>({
        path: '/v1/net-charge/orders/all/frequents',
        method: 'GET',
      }),
    ),
  };

  public readonly user = {
    detail: createResource(['user-detail'], () =>
      this.request<User>({
        path: '/v1/net-charge/user',
        method: 'GET',
      }),
    ),
    internetNumbers: createResource(['user-internet-numbers'], () =>
      this.request<InternetNumbers>({
        path: '/v1/net-charge/user/numbers/internet',
        method: 'GET',
      }),
    ),
    chargeNumbers: createResource(['user-charge-numbers'], () =>
      this.request<ChargeeNumbers>({
        path: '/v1/net-charge/user/numbers/charge',
        method: 'GET',
      }),
    ),
  };
}

export const client = new Client({
  baseUrl: isDevelopment
    ? 'http://localhost:3001/api'
    : 'https://panel.vuecasts.ir/api',
});
