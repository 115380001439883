/* @refresh reload */
import { Route, Router } from '@solidjs/router';
import { lazy, type JSX } from 'solid-js';
import { render } from 'solid-js/web';

import 'solid-devtools';
import './index.scss';
import 'features/commons/WebComponent';
import 'features/commons/Icons';
import RootLayout from './layouts/RootLayout';
import AppProvider from './providers/AppProvider';
import { QueryClient } from './query';
import { useUser } from './query/hooks/user';

const Root = lazy(() => import('./pages/Root'));
const Profile = lazy(() => import('./pages/Profile'));
const Orders = lazy(() => import('./pages/Orders'));
const ChargeOverviewPage = lazy(() => import('./pages/ChargeOverviewPage'));
const InternetOverviewPage = lazy(() => import('./pages/InternetOverviewPage'));
const InternetPlansPage = lazy(() => import('./pages/InternetPlansPage'));
const ChargePlansPage = lazy(() => import('./pages/ChargePlansPage'));

const root = document.getElementById('root');

if (import.meta.env.DEV && !(root instanceof HTMLElement)) {
  throw new Error(
    'Root element not found. Did you forget to add it to your index.html? Or maybe the id attribute got misspelled?',
  );
}

const rootLoad = () => {
  return useUser();
};

const Shell = (): JSX.Element => (
  <QueryClient>
    <AppProvider>
      <Router root={RootLayout}>
        <Route path="/" component={Root} preload={rootLoad} />
        <Route path="/profile" component={Profile} />
        <Route path="/orders" component={Orders} />
        <Route path="/charge/overview" component={ChargeOverviewPage} />
        <Route path="/internet/overview" component={InternetOverviewPage} />
        <Route path="/charge/:operator/plans" component={ChargePlansPage} />
        <Route path="/internet/:operator/plans" component={InternetPlansPage} />
      </Router>
    </AppProvider>
  </QueryClient>
);

render(() => <Shell />, root!);
