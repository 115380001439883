import { createEffect, type ParentComponent } from 'solid-js';

import { useAppData } from '@/providers/AppProvider';
import {
  useUserChargeNumbers,
  useUser,
  useUserInternetNumbers,
} from '@/query/hooks/user';

const RootLayout: ParentComponent<{}> = (props) => {
  const { setStore } = useAppData();
  const userQuery = useUser();
  const userChargeNumbers = useUserChargeNumbers();
  const userInternetNumbers = useUserInternetNumbers();

  createEffect(() => {
    if (userQuery.data && userInternetNumbers.data && userChargeNumbers.data) {
      setStore('userInfo', userQuery.data);
      setStore('internetNumbers', userInternetNumbers.data);
      setStore('chargeNumbers', userChargeNumbers.data);
      setStore('init', true);
    }
  });

  return <div>{props.children}</div>;
};

export default RootLayout;
