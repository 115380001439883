import { QueryClient, QueryClientProvider } from '@tanstack/solid-query';
import { SolidQueryDevtools } from '@tanstack/solid-query-devtools';
import { type ParentComponent } from 'solid-js';

import { isProduction } from '@/env';
import init from '@/query/prefetch';

const queryClient = new QueryClient();
init(queryClient);

const query: ParentComponent = (props) => {
  return (
    <QueryClientProvider client={queryClient}>
      {props.children}
      {!isProduction && <SolidQueryDevtools initialIsOpen={false} />}
    </QueryClientProvider>
  );
};

export const useQueryClient = () => {
  return queryClient;
};

export default query;
