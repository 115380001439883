import { type ParentComponent, createContext, useContext } from 'solid-js';

import { store, setStore } from '@/store';

const AppProviderContext = createContext();

export type ServiceNames = 'Internet' | 'Charge';
type ContextValue = {
  store: typeof store;
  setStore: typeof setStore;
  isServiceEnabled: (_: ServiceNames) => boolean;
  selectService: (_: ServiceNames) => void;
  removeSelectedService: () => void;
};

const AppProvider: ParentComponent = (props) => {
  const selectService = (serviceName: ServiceNames) => {
    if (store.init) {
      setStore('selectedService', serviceName);
    }
  };

  const removeSelectedService = () => {
    if (store.init) {
      setStore('selectedService', undefined);
    }
  };

  const isServiceEnabled = (serviceName: ServiceNames) => {
    return store.init && store.selectedService === serviceName;
  };

  const values = {
    store,
    setStore,
    isServiceEnabled,
    selectService,
    removeSelectedService,
  } as ContextValue;

  return (
    <AppProviderContext.Provider value={values}>
      {props.children}
    </AppProviderContext.Provider>
  );
};

export const useAppData = () => {
  return useContext(AppProviderContext) as ContextValue;
};

export default AppProvider;
