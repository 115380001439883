import { QueryClient } from '@tanstack/solid-query';

import { client } from './Client';

export default function (queryClient: QueryClient) {
  queryClient.prefetchQuery({
    queryKey: client.orders.list.all.key,
    queryFn: () => client.orders.list.all.fetcher(),
  });
}
