import { createStore } from 'solid-js/store';

import { ChargeeNumbers, InternetNumbers, UserInfo } from '@/types/user';

type StoreRaw = {
  init: boolean;
  userInfo: UserInfo;
  selectedService: 'Internet' | 'Charge';
  internetNumbers: InternetNumbers;
  chargeNumbers: ChargeeNumbers;
};

type Store =
  | ({ init: false } & Partial<StoreRaw>)
  | ({ init: true } & StoreRaw);

const initStore = () => {
  const [store, setStore] = createStore<Store>({
    init: false,
    userInfo: undefined!,
    selectedService: undefined!,
    internetNumbers: [],
    chargeNumbers: [],
  });

  return {
    store,
    setStore,
  };
};

const { store, setStore } = initStore();

export { store, setStore };
