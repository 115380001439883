import { createQuery } from '@tanstack/solid-query';

import { client, createQueryPayload } from '@/query/Client';

export const useUser = () => {
  return createQuery(() => ({
    ...createQueryPayload(client.user.detail),
    retry: 3,
    staleTime: 15 * 60 * 1000,
  }));
};

export const useUserInternetNumbers = () => {
  return createQuery(() => ({
    ...createQueryPayload(client.user.internetNumbers),
    retry: 3,
    staleTime: 15 * 60 * 1000,
  }));
};

export const useUserChargeNumbers = () => {
  return createQuery(() => ({
    ...createQueryPayload(client.user.chargeNumbers),
    retry: 3,
    staleTime: 15 * 60 * 1000,
  }));
};
